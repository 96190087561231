import axios from "axios";
import swal from "sweetalert";
import { tokenExpired } from "@/api/errorMessages";
export default {
  namespaced: true,
  state: {
    filesKey: null,
  },
  actions: {
    async generateFilesKey(_,) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `/api/users/secret-key`,
        );
        if (response.status == 200) {
          console.log("generated");
        }
        return response.data;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 422) {
          swal({
            title: `Ocurrió un error`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    },
    async updateFilesKey (_,) {
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BACK_ROUTE + `/api/users/secret-key-7zip`,
        );
        if (response.status == 201) {
          console.log("updated");
        }
        return response.data;
      } catch (error) {
        if (error.response.status == 401) {
          tokenExpired();
        }
        if (error.response.status == 422) {
          swal({
            title: `Ocurrió un error`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
        if (error.response.status == 404) {
          swal({
            title: `Ocurrió un error`,
            text: "Por favor, intentar nuevamente.",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    }
  },
};
